<template>
  <div
    class="flex items-center rounded text-sm shadow"
    :class="[colorClasses, { 'p-2': compact, 'p-4': !compact }]"
    role="alert"
  >
    <EpIcon class="mr-2" size="lg" :name="icon" />
    <p><slot /></p>
    <button
      v-if="!hideClose"
      type="button"
      class="-mx-1.5 -my-1.5 inline-flex ml-auto p-1.5 rounded-lg w-8"
      :class="colorClasses"
      aria-label="close button"
      @click="emit('close')"
    >
      <span class="sr-only">Close</span>
      <EpIcon name="close-fill" size="2xl" />
    </button>
  </div>
</template>

<script>
const ERROR = 'error';
const INFO = 'info';
const SUCCESS = 'success';
const WARNING = 'warning';
const STATUSES = [ERROR, INFO, SUCCESS, WARNING];
const COLOR_CLASSES_MAP = {
  [ERROR]: 'bg-red-50 dark:bg-gray-700 text-red-700 dark:text-red-400',
  [INFO]: 'bg-blue-50 dark:bg-gray-700 text-blue-700 dark:text-blue-400',
  [SUCCESS]: 'bg-green-50 dark:bg-gray-700 text-green-700 dark:text-green-400',
  [WARNING]: 'bg-yellow-100 dark:bg-gray-700 text-yellow-700 dark:text-yellow-400',
};
const ICON_MAP = {
  [ERROR]: 'error-warning-fill',
  [INFO]: 'information-fill',
  [SUCCESS]: 'checkbox-circle-fill',
  [WARNING]: 'alert-fill',
};
</script>

<script setup>
import { computed } from 'vue';

import EpIcon from '@/Components/EpIcon';

const emit = defineEmits(['close']);

const props = defineProps({
  compact: {
    type: Boolean,
    default: false,
  },

  hideClose: {
    type: Boolean,
    default: false,
  },

  status: {
    type: String,
    default: INFO,
    validator: (value) => STATUSES.includes(value),
  },
});

const colorClasses = computed(() => {
  return COLOR_CLASSES_MAP[props.status];
});

const icon = computed(() => {
  return ICON_MAP[props.status];
});
</script>
